import React from 'react';
import spacex from "../assets/spacex.png"
import microsoft from "../assets/microsoft.png"
import gouv from "../assets/gouv3.png"
import binance from "../assets/binance.png"


const Footer = () => {
    return (
        <footer className='bg-black border-t-slate-100 pt-2 pb-10'>
            <h2 className='text-white font-bold text-6xl font-display text-center p-2'>IN GNT WE TRUST</h2>
        
            <div>
                <img src={spacex} alt="spacex"/>
                <img src={microsoft} alt="spacex"/>
                <img src={gouv} alt="spacex"/>
                <img src={binance} alt="spacex"/>
            </div>
        </footer>
    );
}

export default Footer;
